module.exports = {
  siteMetadata: {
    title: `FANTASIE - der mystische Laden`,
    subtitle: 'Anonym einkaufen, weiterbilden, "Ballast abladen"',
    description: `FANTASIE, der mystische Laden, lässt (fast) keine Wünsche offen. 
              Er ist im weiteren Sinne eine Erscheinung, welche Ihnen sogar helfen kann,
              zwischen weltlichen und geistlichen (und sphärischen) Bedürfnissen zu unterscheiden.
              Anonymität ist dabei die oberste Direktive.`,
    label:  `Veranstaltung, Kurs, Gruppe, Ware, Spielware, Rollenspiel, verkleiden, Schneider, Schreiner, Künstler,
              Eventmanagement, Umzug, Hund, Pferd, Erziehung, Lernen, Wissen, Bildung, Erwachsenenbildung, 
              Volksbildung, Weiterbildung, Organisation, Kanban, Tanz, Basteln, Werken, Psyche, Psycho, Couching,
              Coaching, Handarbeit, Stricken, Häkeln, Spinnen, Weben, Auftragsarbeit, 
              Beraten, Kummer, Ballast, Seele, Selbstfindung, Lebensberatung, Shop, Beratung, Hilfe,
              Unterstützung, Seminar, Alex Schneider, Orcana, Orcana01, SoCraTes, Anonym, Anonymität`,
    author: `Orcana01`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/Fantasie-Logo-trans-icon.png`, // This path is relative to the root of the site.
      },
    },
  ],
}
