/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import LogoImage from "../components/logo_image";
import PpImage from "../components/pp_image";
import IbanImage from "../components/iban_image";

import { Link } from "gatsby";

import "./layout.css";
import "./css/bootstrap-grid.css";
import "./css/bootstrap-reboot.css";
import "./css/bootstrap.css";
import "./fdml.css";
import config from "../../gatsby-config";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <link rel="stylesheet" href="/css/bootstrap.min.css"></link>
        <link rel="stylesheet" href="/css/solid.css"></link>

        <div className="container">
          <div className="under-construction">
            Geschäftspause von Sommer 2022 bis voraussichtlich Sommer 2025.
          </div>

          <div className="row">
            <ul className="nav nav navbar-nav" style={{ textAlign: "center" }}>
              <span style={{ textAlign: "left" }}>zur Startseite:</span>
              <li className="nav-item" style={{ margin: "0 auto" }}>
                <Link to="//#maintitle" title="zur Startseite">
                  <div style={{ width: "12em" }}>
                    <LogoImage />
                  </div>
                </Link>
              </li>

              <p></p>

              <li className="nav-item">
                <Link
                  to="/fdml_1_aktuelles#ueberschrift"
                  title="Veranstaltungen &amp; mehr"
                  className="mainlink"
                >
                  Aktuelles
                </Link>
                <br />
                <Link
                  to="/fdml_1a_aktuelles-klartext#ueberschrift"
                  title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr"
                  style={{ fontSize: "1.0em" }}
                >
                  zum Klartext
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/fdml_2_warensortiment#ueberschrift"
                  title="Informationen zum Warenangebot"
                  className="mainlink"
                >
                  Warensortiment
                </Link>
                <br />
                <Link
                  to="/fdml_2a_warensortiment-klartext#ueberschrift"
                  title="Detaillierte Informationen zum Warenangebot"
                  style={{ fontSize: "1.0em" }}
                >
                  zum Klartext
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/fdml_3_auftragsarbeiten#ueberschrift"
                  title="Informationen zum Produktionsangebot"
                  className="mainlink"
                >
                  Auftragsarbeiten
                </Link>
                <br />
                <Link
                  to="/fdml_3a_auftragsarbeiten-klartext#ueberschrift"
                  title="Detaillierte Informationen zum Produktionsangebot"
                  style={{ fontSize: "1.0em" }}
                >
                  zum Klartext
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/fdml_4_dienstleistungen#ueberschrift"
                  title="Informationen zum Serviceangebot"
                  className="mainlink"
                >
                  Dienstleistungen
                </Link>
                <br />
                <Link
                  to="/fdml_4a_dienstleistungen-klartext#ueberschrift"
                  title="Detaillierte Informationen zum Serviceangebot"
                  style={{ fontSize: "1.0em" }}
                >
                  zum Klartext
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/fdml_5_weiterbildung#ueberschrift"
                  title="Informationen zum Lern- und Verbesserungsangebot"
                  className="mainlink"
                >
                  Weiterbildung
                </Link>
                <br />
                <Link
                  to="/fdml_5a_weiterbildung-klartext#ueberschrift"
                  title="Detaillierte Informationen zum Lern- und Verbesserungsangebot"
                  style={{ fontSize: "1.0em" }}
                >
                  zum Klartext
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/fdml_6_beratung#ueberschrift"
                  title="Informationen zum Beratungsangebot"
                  className="mainlink"
                >
                  Beratung
                </Link>
                <br />
                <Link
                  to="/fdml_6a_beratung-klartext#ueberschrift"
                  title="Detaillierte Informationen zum Beratungsangebot"
                  style={{ fontSize: "1.0em" }}
                >
                  zum Klartext
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/fdml_7_kummerkasten#ueberschrift"
                  title="Informationen zum Gesprächsangebot"
                  className="mainlink"
                >
                  Kummerkasten
                </Link>
                <br />
                <Link
                  to="/fdml_7a_kummerkasten-klartext#ueberschrift"
                  title="Detaillierte Informationen zum Gesprächsangebot"
                  style={{ fontSize: "1.0em" }}
                >
                  zum Klartext
                </Link>
              </li>

              <p></p>

              <li className="nav-item">
                <Link
                  to="/fdml_8_historie#ueberschrift"
                  title="allgemeine Informationen zur Entstehung und zum Ausbau des 'Ladens'"
                  className="mainlink"
                >
                  Historie
                </Link>
              </li>

              <p style={{ fontSize: "1.2em", lineHeight: "1.7" }}>
                A.&nbsp;Schneider:
                <br />
                <a
                  href="tel:+49-9187-7081220"
                  title="anrufen"
                  style={{ fontSize: "1em" }}
                >
                  +49 (0) 91 87 / 70 81 220
                </a>
                <br />
                <a
                  href="mailto:fantasie@fantasie-dml.de"
                  title="per E-Mail kontaktieren"
                  style={{ fontSize: "1em" }}
                >
                  fantasie@fantasie-dml.de
                </a>
                <div style={{ color: "#000000" }}>
                  Copyright&thinsp;©&thinsp;1996&ensp;A.&nbsp;Schneider
                  <br />
                  Webseiten-Stand: &thinsp;©&thinsp;{new Date().getFullYear()}
                </div>
              </p>
            </ul>

            <div className="col" style={{ textAlign: "center" }}>
              <header
                style={{
                  marginTop: "1em",
                  marginBottom: "2em",
                  lineHeight: "1.0",
                }}
              >
                <p
                  style={{
                    fontSize: "2.5em",
                    fontWeight: "bold",
                    marginBottom: "0.1em",
                  }}
                  id="maintitle"
                >
                  {data.site.siteMetadata.title}
                </p>

                <p>{config.siteMetadata.subtitle}</p>
              </header>

              {children}

              <footer>
                <div className="container">
                  <div className="row">
                    <div style={{ width: `17em`, margin: "auto" }}>
                      <PpImage />
                    </div>
                    <div style={{ margin: "auto" }}>
                      <p></p>
                      <div style={{ width: `12em`, margin: "auto" }}>
                        <LogoImage />
                      </div>
                      <Link
                        to="/fdml_b1_impressum/"
                        title="Verantwortlichkeit und rechtliche Hinweise"
                      >
                        Impressum
                      </Link>
                      <span style={{ fontSize: "1.5em" }}> &amp; </span>
                      <Link
                        to="/fdml_b2_datenschutz/"
                        title="Datenschutzerklärung und Personenbezogene Daten"
                      >
                        Datenschutz
                      </Link>
                    </div>
                    <div style={{ width: `17em`, margin: "auto" }}>
                      <IbanImage />
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>

        <script
          src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
          integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
          integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
          crossOrigin="anonymous"
        ></script>
        <script src="/js/bootstrap.bundle.min.js"></script>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
